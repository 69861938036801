<template>
  <div>
    <v-progress-linear
      :active="getLoaderStatus"
      indeterminate
      fixed
      top
      color="primary"
      height="5px"
      style="z-index: 500"
    ></v-progress-linear>
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <v-navigation-drawer
      :value="getToggleSlider"
      :key="`offer-1`"
      fixed
      temporary
      :stateless="keepOpen"
      overlay-opacity="0.8"
      right
      width="80%"
      class="fill-height"
    >
      <v-toolbar outlined flat dense light class="navbar-fixed-header">
        <v-btn icon class="mr-1" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <h5 class="ml-5 text-uppercase" v-if="formType === 'add'">
          Add Invoice
        </h5>
        <h5 class="ml-5 text-uppercase" v-if="formType === 'edit'">
          Update Invoice
        </h5>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-navigation-drawer>
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <top-nav-bar :mini="mini"></top-nav-bar>
    <v-main
      :class="{ 'detail-content-margin': listingDrawer }"
      :style="[mini ? { 'padding-left': '56px' } : { 'padding-left': '232px' }]"
      class="details-content"
    >
      <!--      <v-col>
        <v-row>
          <v-col class="pr-0 mt-5 ml-5 col-md-5 col-lg-4 col-xl-3">
            <v-card class="border-radius">
              <v-col class="ma-3">
                <p class="grey&#45;&#45;text font-bold">ACTIVE SUBSCRIPTION VALUE</p>
                <h5 class="font-bold">€ 99,99</h5>
                <h6 class="mt-3 body-1">Next Subscription due at <span class="font-bold">20th August 2021</span></h6>
              </v-col>
            </v-card>
          </v-col>
          <v-col class="pr-0 mt-5 ml-5 col-md-5 col-lg-4 col-xl-3">
            <v-card class="border-radius">
              <v-col class="ma-3">
                <v-row>
                  <v-col>
                    <p class="grey&#45;&#45;text font-bold">ACTIVE HOTELS</p>
                    <h5 class="font-bold">5</h5>
                  </v-col>
                  <v-col>
                    <p class="grey&#45;&#45;text font-bold">ACTIVE LOCATIONS</p>
                    <h5 class="font-bold">5</h5>
                  </v-col>
                </v-row>
                <h6 class="mt-3 body-1">Next Subscription due at <span class="font-bold">20th August 2021</span></h6>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>-->
      <v-col>
        <v-card class="mt-3 ma-5" elevation="15">
          <div>
            <div class="pt-4 text-h6 ma-2">Invoice Management</div>
            <v-divider class="mt-8 mb-4" color="default"></v-divider>
            <v-row class="ma-2">
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  clearable
                  single-line
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <organization-selector
                  @org-selected="orgSelected"
                  label="Select Organization"
                ></organization-selector>
              </v-col>
            </v-row>

            <v-divider class="my-4" color="default"></v-divider>
            <v-data-table
              :loading="false"
              :search="search"
              :headers="headers"
              :items="filteredInvoices"
              class="elevation-1"
            >
              <template v-slot:item.billed_date="{ item }">
                {{ moment(item.billed_date).format("MMM DD YYYY") }}
              </template>
              <template v-slot:item.due_date="{ item }">
                {{ moment(item.due_date).format("MMM DD YYYY") }}
              </template>
              <template v-slot:item.total_amount="{ item }">
                <strong
                  ><span class="text-uppercase">€</span>
                  {{
                    item.total_amount ? item.total_amount : 0 | applyDecimal
                  }}</strong
                >
              </template>
              <!--              <template v-slot:item.discount_percentage="{ item }">-->
              <!--                {{item.discount_percentage + ' %' }}-->
              <!--              </template>-->
              <template v-slot:item.status="{ item }">
                <v-chip
                  small
                  label
                  :color="item.status | statusColor"
                  class="my-1 ml-2 border-radius"
                  outlined
                  :style="[
                    item.status === 'paid'
                      ? { 'background-color': '#E5F4E6 !important' }
                      : { 'background-color': '#FEE4E2 !important' },
                  ]"
                >
                  {{ item.status | status }}
                </v-chip>
                <v-chip
                  small
                  label
                  color="default"
                  class="ml-1"
                  v-if="item.status === 'cancelled'"
                >
                  {{ item.parent_number }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      small
                      class="mr-2 pdf-icon"
                      @click.stop="downloadPdf(item.enc)"
                      >mdi-file-pdf-box</v-icon
                    >
                  </template>
                  <span>Preview Invoice</span>
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    getAuthUser.user_type === 'seller' &&
                    item.status === 'pending'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" icon small @click.stop="payNow(item.id)">
                      <v-icon>mdi-credit-card</v-icon>
                    </v-btn>
                  </template>
                  <span
                    ><strong
                      ><span class="text-uppercase">€</span>
                      {{ item.total_amount | applyDecimal }}</strong
                    >
                    Pay Now</span
                  >
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    getAuthUser.user_type === 'admin' &&
                    item.status === 'pending'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      icon
                      small
                      @click.stop="payNow(item.id, 'mark_as_paid')"
                    >
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark As Paid</span>
                </v-tooltip>

                <!--                <v-icon-->
                <!--                    small-->
                <!--                    @click="deleteInvoiceItem(item)"-->
                <!--                >mdi-delete</v-icon>-->
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-main>

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :session-id="sessionId"
      @loading="(v) => (loading = v)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment-timezone";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import Snackbar from "@/components/Snackbar";
const TopNavBar = () =>
  import(/* webpackChunkName: "top-nav-bar" */ "@/components/TopNavBar");
export default {
  name: "Invoices",
  components: { TopNavBar, Snackbar, StripeCheckout },
  data: () => ({
    // Stripe (START)
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    sessionId: null,
    loading: false,
    // Stripe (END)

    mini: false,
    listingDrawer: true,
    search: "",
    selectedOrganizationId: null,
    headers: [
      { text: "Invoice #", value: "number", width: "13%" },
      { text: "Billed Date", value: "billed_date", width: "13%" },
      { text: "Due Date", value: "due_date", width: "13%" },
      { text: "Amount", value: "total_amount", width: "13%" },
      // {text: 'Discount', value: 'discount_percentage'},
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false, width: 100 },
    ],
    keepOpen: true,
    form: null,
    snackbarMessage: null,
    formType: null,
  }),
  async mounted() {
    this.loadInvoices();
    this.loadOrganizations();
    this.initForm();
    this.initSocketEventsListener();
  },
  computed: {
    filteredInvoices() {
      let invoices = this.getInvoices;
      if (this.selectedOrganizationId !== null) {
        invoices = _.filter(invoices, {
          organization_id: this.selectedOrganizationId,
        });
      }
      return _.orderBy(invoices, ["created_at"], ["desc"]);
    },
    ...mapGetters([
      "getAuthUser",
      "getInvoices",
      "getToggleSlider",
      "getNewInvoice",
      "getInvoicesLoadStatus",
      "getLoaderStatus",
      "getInvoiceDeleteStatus",
      "getInvoiceErrors",
      "getOrganizations",
      "getStripeSessionId",
    ]),
  },
  methods: {
    orgSelected(orgId) {
      this.selectedOrganizationId = orgId;
    },
    async payNow(id, type = "checkout") {
      this.loading = true;
      if (type === "mark_as_paid") {
        this.setInvoice({ id: id, status: "paid" });
        await this.updateInvoice({ id: id });
      } else {
        this.setStripeSessionId(null);
        await this.createSessionByInvoiceId({ id: id });
        this.sessionId = this.getStripeSessionId;
        if (this.sessionId) {
          this.$refs.checkoutRef.redirectToCheckout();
        } else {
          this.snackbarMessage = "There is no subscription found.";
          this.$refs.snackbar.type = "error";
          this.$refs.snackbar.show = true;
        }
      }
      this.loading = false;
    },
    moment,
    initForm() {
      let invoice = this.getNewInvoice;
      this.form = new Form(invoice);
    },
    minimizeList(status) {
      this.mini = status;
    },
    async openInvoiceSlider(state, id = null) {
      this.formType = state;
      this.setToggleSlider(true);
    },
    close() {
      this.initForm();
      this.setToggleSlider(false);
    },
    capitalize(words) {
      if (words !== "" && words !== null) {
        return words.toUpperCase();
      }
    },
    async deleteInvoiceItem(item) {
      let invoiceItem = Object.assign({}, item);
      await this.deleteInvoice(invoiceItem);
      if (this.getInvoiceDeleteStatus === 3) {
        this.snackbarMessage = this.getInvoiceErrors.error;
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
      }
    },
    downloadPdf(enc) {
      window.open("proforma-invoice?inv=" + enc, "_blank");
    },

    initSocketEventsListener() {
      let userType = this.getAuthUser.user_type;
      let listenerID =
        userType == "seller"
          ? this.getAuthUser.account_id
          : this.getAuthUser.id;
      let vendorAccountChannel = Echo.channel(`miceloc_vendor.${listenerID}`);
      // Invoice Created
      vendorAccountChannel.listen(".invoice.create", (e) => {
        this.$store.commit("updateInvoiceList", e.invoice);
      });

      // Invoice Updated
      // vendorAccountChannel.listen(".vendor.update", (e) => {
      //   console.log(e);
      //   this.$store.commit("updateInvoiceList", e.invoice);
      // });
    },
    ...mapActions([
      "logout",
      "loadInvoices",
      "deleteInvoice",
      "downloadInvoicePdf",
      "loadOrganizations",
      "createSessionByInvoiceId",
      "updateInvoice",
    ]),
    ...mapMutations(["setToggleSlider", "setStripeSessionId", "setInvoice"]),
  },
  watch: {},
};
</script>

<style scoped>
.border-radius {
  border-radius: 2px !important;
  border: 0 !important;
}
.pdf-icon {
  border-radius: 50%;
  padding: 4px;
  background-color: #e1e1e1;
}
</style>
